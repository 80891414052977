import React, { useState } from "react";

import LaboratoryUriIcon from "assets/icons/lab.svg";
import NurseUriIcon from "assets/icons/nurse-intensive.svg";
import SpecialistUriIcon from "assets/icons/specialist.svg";
import EmergencyUriIcon from "assets/icons/emergency-location.svg";
import UltrassomUriIcon from "assets/icons/us-heart.svg";
import DiagnosticUriIcon from "assets/icons/diagnostic.svg";

import Title from "components/Title";
import Modal from "components/Modal";

import * as S from "./styles";

interface IProps {
  isDesktop?: boolean;
  section?: string;
}

interface ServiceItem {
  icon: any;
  title: string;
  description: string;
}

export default function Services({ isDesktop, section }: IProps) {
  const [visibleModal, setVisibleModal] = useState(false);
  const [serviceItem, setServiceItem] = useState<ServiceItem>({
    icon: "",
    title: "",
    description: "",
  });

  const services = [
    {
      title: "Pronto atendimento",
      description: "Plantão presencial 24 horas.",
      icon: EmergencyUriIcon,
      size: 30,
    },
    {
      title: "Cirurgias",
      description: "Contamos com centro cirúrgico amplamente equipado.",
      icon: UltrassomUriIcon,
      size: 34,
    },
    {
      title: "Internamento",
      description: "Setorizado e com médicos veterinários 24h.",
      icon: NurseUriIcon,
      size: 34,
    },
    {
      title: "Especialidades",
      description: "Contamos com completa equipe de especialistas.",
      icon: SpecialistUriIcon,
      size: 34,
    },
    {
      title: "Laboratório",
      description: "Contamos com estrura de laboratório interno.",
      icon: LaboratoryUriIcon,
      size: 32,
    },
    {
      title: "Diagnóstico imagem",
      description: "Radiografia digital e ultrassonografia.",
      icon: DiagnosticUriIcon,
      size: 32,
    },
  ];

  function handleModal(item: ServiceItem) {
    setServiceItem({
      icon: item.icon,
      title: item.title,
      description: item.description,
    });

    setVisibleModal(true);
  }

  return (
    <S.Container id={section} isDesktop={isDesktop}>
      <S.TextContainer isDesktop={isDesktop}>
        <Title
          title="Aqui você encontra tudo"
          titleColor="que seu pet precisa"
          subtitle="Confira os detalhes"
          isDesktop={isDesktop}
        />
      </S.TextContainer>

      <S.ServiceItems isDesktop={isDesktop}>
        {services.map((service) => {
          return (
            <S.CardService isDesktop={isDesktop}>
              <S.IconCard
                src={service.icon}
                size={service.size}
                alt="laboratório homeopatas"
              />
              <S.TitleCard>{service.title}</S.TitleCard>
              <S.TextCard>{service.description}</S.TextCard>
            </S.CardService>
          );
        })}
      </S.ServiceItems>

      {visibleModal && (
        <Modal isLoading={false} handleClose={() => setVisibleModal(false)}>
          <S.TitleCard>{serviceItem.title}</S.TitleCard>
        </Modal>
      )}
    </S.Container>
  );
}
