import React from "react";

import TeamUri from "assets/images/team.png";
import LaisUri from "assets/images/lais.png";
import SamucaUri from "assets/images/samuel.png";
import MonicaUri from "assets/images/monica.png";
import LarissaUri from "assets/images/larissa.png";
import { Carousel } from "components/Carousel";
import Title from "components/Title";

import useWindowDimensions from "hooks/window-dimensions";
import defaultTheme from "styles/theme";

import * as S from "./styles";

interface IProps {
  isDesktop?: boolean;
  section?: string;
}

export default function Team({ isDesktop, section }: IProps) {
  const { width } = useWindowDimensions();
  const { mobile } = defaultTheme.screensSize;

  const carouselItemWidth = width < mobile ? 180 : 558;
  const carouselItemHeight = width < mobile ? 180 : 368;

  const carouselContent: any[] = [
    {
      image: (
        <img
          src={LaisUri}
          alt="Dra. Lais Paganelli"
          width={carouselItemWidth}
          height={carouselItemHeight}
        />
      ),
      text: {
        title: "Dra. Lais Paganelli",
        description: `Graduação:
          Formada em Medicina Veterinária em 2012 pela Faculdade Evangélica do Paraná em Curitiba - PR. 
          Especializada: 
          Pós graduada em Clínica Médica é Cirúrgica pelo Qualittas 2013 e 2014. 
          Pós graduada em Cirurgia de cães e gatos pela Anclivepa PR 2014 e 2015.
          Pós graduada em Cirurgia de Tecidos Moles de cães e gatos pela Anclivepa SP 2019 a 2021.
          Cursos:
          Curso de Cirurgias Avançadas pela Unesp Jaboticabal 2022.
          Curso de Cirurgia de Toráx pela PEC 2022.
          Monitora da Pós graduação de cirurgia da Anclivepa SP 2022 e 2023. 
          Curso de Medicina Paliativa Instituto Kairós 2023. 
          Curso de Oftalmologia Clínica e Cirúrgica Anclivepa SP 2023 e 2024. 
          Histórico: 
          Diretora e cirurgiã do Hospital Veterinário Homeopatas desde 2012.`,
      },
    },
    {
      image: (
        <img
          src={SamucaUri}
          alt="Samuel Paganelli"
          width={carouselItemWidth}
          height={carouselItemHeight}
        />
      ),
      text: {
        title: "Dr. Samuel Paganelli",
        description: `Graduação: 
          Formado pela UDESC em Lages SC no ano de 1990.          
          Pós graduação:          
          Pós graduação em Homeopatia  pelo Colégio Brasileiro de Homeopatia Constantine Hering - Curitiba PR.          
          Pós graduação em clínica e cirurgia pela Udesc - Lages SC.
          Histórico:           
          Iniciou a carreira como Médico Veterinário da Prefeitura de Lontras SC.
          Em seguida assumiu como Médico Veterinário e diretor do zoológico do Beto Carrero World, por 15 anos.
          Fundou junto a sua esposa Ana Pena Paganelli o Hospital Veterinário Homeopatas em 1996. 
          Hoje coordena o laboratório de análises clínicas do laboratório Homeopatas e atua exclusivamente na sua especialidade de Homeopatia Veterinária.`,
      },
    },
    {
      image: (
        <img
          src={MonicaUri}
          alt="Dra. Mônica Jaguszeski"
          width={carouselItemWidth}
          height={carouselItemHeight}
        />
      ),
      text: {
        title: "Dra. Mônica Jaguszeski",
        description: `Graduação:
          Formada em Medicina Veterinária pela Faculdade Federal da Fronteira Sul em 2019.
          Especializada: 
          Geriatria,  
          Anestesiologia,
          Cardiologia.
          Cursos:
          Hemogasometria.
          Hematologia - transfusão de sangue.          
          Histórico: 
          Dra. Mônica é responsável pelo setor de anestesiologia, geriatria e cardiologia do hospital. Nossa história iniciou quando ela ainda era nossa estagiária e está em nossa equipe desde 2019 quando se formou.`,
      },
    },
    {
      image: (
        <img
          src={LarissaUri}
          alt="Dra. Larissa Moraes"
          width={carouselItemWidth}
          height={carouselItemHeight}
        />
      ),
      text: {
        title: "Dra. Larissa Moraes",
        description: `Graduação:
         Formada pela Universidade São Judas Tadeu em 2020.         
         Especializada: 
         Clínica Médica de Felinos          
         Cursos: 
         Emergência e Neurologia Veterinária.         
         Histórico:          
         Dra. Larissa é responsável pelo setor de Felinos em nosso hospital desde 2021.`,
      },
    },
    {
      image: (
        <img
          src={TeamUri}
          alt="homeopatas-veterinarios"
          width={carouselItemWidth}
          height={carouselItemHeight}
        />
      ),
      text: {
        title: "Equipe de Médicos Veterinários 24",
        description: `Dra. Emily 
           Dra. Regiane 
           Dra. Laura 
           Dra. Karolayn 
           Dra. Kelly 
           Dra. Letícia 
           Dra. Adrielle 
           Dr. João`,
      },
    },
  ];

  return (
    <S.Container id={section} isDesktop={isDesktop}>
      <S.TextContainer isDesktop={isDesktop}>
        <Title
          title="Nossos Médicos"
          titleColor="Veterinários"
          subtitle="Contamos com um time de especialistas"
          isDesktop={isDesktop}
        />
      </S.TextContainer>

      <Carousel
        isDesktop={isDesktop}
        vet={!isDesktop}
        autoScroll
        items={carouselContent}
        carouselHeight={carouselItemHeight}
        itemWidth={carouselItemWidth}
      />
    </S.Container>
  );
}
