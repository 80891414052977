import styled from 'styled-components';

export const Container = styled.footer<{isDesktop?: boolean}>`
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isDesktop }) => isDesktop ? 'row' : 'column'};
  align-items: center;
  width: 100%;
  padding: 24px;
  background-color: ${({ theme }) => theme.palette.mono.background};
  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    align-items: flex-start;
  };
`;

export const Column = styled.div`
  flex-direction: column;
  justify-content: flex-start;
`;

export const ColumnAboutCompany = styled.div`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  flex-direction: column;
  align-items: center;
`;

export const RightContainer = styled.div`
  flex-direction: column;
  align-items: center;
`;

export const Row = styled.div<{ center: boolean }>`
  width: 100%;
  max-width: 1180px;
  align-items: ${({ center }) => (center ? 'center' : 'space-between')};
  justify-content: space-between;
`;

export const Logo = styled.img<{isMobile: boolean}>`
  margin-top: ${({ isMobile }) => isMobile ? 16 : 0}px;
  width: 42px;
  height: 40px;
  margin-right: 8px;
`;

export const IconButton = styled.button`
  margin-right: 6px;
  :hover {
    transition: opacity 0.3s linear;
    opacity: 0.4;
  }
`;

export const Icon = styled.img`
  width: 25px;
  height: 25px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    margin-top: 10px;
  }
`;

export const RowIcons = styled.div`
  align-self: flex-end;
  margin-bottom: 4px;
`;

export const Button = styled.button``;

export const ButtonText = styled.span`
  width: fit-content;
  margin: 6px 0;
  :hover {
    transition: opacity 0.3s linear;
    opacity: 0.4;
  }
`;

export const FaqTitle = styled.h1`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
`; 

export const Title = styled.h1``;

export const LinkTo = styled.a``;

export const LinkToText = styled.span`
  max-width: 250px;
  margin-top: 8px;
  :hover {
    transition: opacity 0.3s linear;
    opacity: 0.4;
  }
`;

export const TextAccompany = styled.h3`
  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
  }
`;

export const RightFooter = styled.div`
  margin-top: 16px;
  flex-direction: column;
  align-self: flex-end;
`;


export const FlagsPlaceholderRight = styled.div`
  display: none;
  height: 25px;
  width: 56px;
  min-width: 56px !important;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    display: block;
  }
`;
