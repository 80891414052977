import styled from "styled-components";

export const Container = styled.div`
  justify-content: center;
  width: 100%;
`;

export const Content = styled.div`
  flex-direction: column;
  @media screen {
    max-width: ${({ theme }) => `${theme.screensSize.fullHd}px`};
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.hd}px`}) {
    padding: 36px;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.tablet}px`}) {
    padding: 36px;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding-right: 8px;
    width: 110%;
  }
`;

export const Sections = styled.div`
  flex-direction: column;
`;
