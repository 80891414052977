import React from "react";

import scrollSnapPolyfill from "css-scroll-snap-polyfill";

import useWindowDimensions from "hooks/window-dimensions";
import defaultTheme from "styles/theme";

import Header from "components/Header";
import Footer from "components/Footer";
import ScrollTopButton from "components/ScrollTopButton";
import Banner from "./Sections/Banner";
// import About from "./Sections/About";
import Team from "./Sections/Team";
import Services from "./Sections/Services";
import Instagram from "./Sections/Instagram";
import Hospital from "./Sections/Hospital";

import * as S from "./styles";

function Home() {
  const { width } = useWindowDimensions();
  const { hd } = defaultTheme.screensSize;

  function isDesktop() {
    return width > hd;
  }

  window.addEventListener("load", function f() {
    scrollSnapPolyfill();
  });

  const menuItems = [
    { label: "INÍCIO", scrollTargetId: "banner" },
    { label: "SERVIÇOS", scrollTargetId: "services" },
    { label: "HOSPITAL", scrollTargetId: "hospital" },
    { label: "VETERINÁRIOS", scrollTargetId: "team" },
    // { label: "SOBRE NÓS", scrollTargetId: "about" },
    { label: "INSTAGRAM", scrollTargetId: "instagram" },
  ];

  return (
    <S.Container>
      <S.Content>
        <Header items={menuItems} />

        <S.Sections>
          <Banner section="banner" isDesktop={isDesktop()} />
          <Services section="services" isDesktop={isDesktop()} />
          <Hospital section="hospital" isDesktop={isDesktop()} />
          <Team section="team" isDesktop={isDesktop()} />
          {/* <About section="about" isDesktop={isDesktop()} /> */}
          <Instagram section="instagram" isDesktop={isDesktop()} />
        </S.Sections>

        <Footer isDesktop={isDesktop()} />
        <ScrollTopButton />
      </S.Content>
    </S.Container>
  );
}

export default Home;
