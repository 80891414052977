import styled from "styled-components";

interface SliderProps {
  isInfinite: boolean;
}

export const Slider = styled.div<SliderProps>`
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  overscroll-behavior-x: ${({ isInfinite }) =>
    isInfinite ? `contain;` : "auto;"};

  cursor: grab;

  :active:hover {
    cursor: grabbing;
  }
`;
