import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000080;
  z-index: 100;
`;

export const Modal = styled.div`
  background-color: ${({ theme }) => theme.palette.mono.background};
  width: 40%;
  border-radius: 2px;
  overflow: auto;
  max-width: 800px;
  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 80%;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  height: 26px;
  width: 26px;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.secondary.main};
  justify-content: center;
  align-items: center;
  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    right: 10px;
    top: 10px;

    font-size: 20px;
  }
  :hover {
    transition: opacity 0.3s linear;
    opacity: 0.6;
  }
`;
