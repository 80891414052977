import styled from "styled-components";

export const Container = styled.section<{ isDesktop?: boolean }>`
  margin-top: 16px;
  justify-content: space-between;
  flex-direction: ${({ isDesktop }) => (isDesktop ? "row" : "column")};
`;

export const TextContainer = styled.div<{ isDesktop?: boolean }>`
  justify-content: center;
  flex-direction: column;
  margin-top: 32px;
  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
  }
`;

export const ServiceItems = styled.li<{ isDesktop?: boolean }>`
  display: flex;
  margin-top: 24px;
  margin-right: ${({ isDesktop }) => isDesktop && 24}px;
  width: ${({ isDesktop }) => (isDesktop ? 60 : 96)}%;
  padding: 16px;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const CardService = styled.div<{ isDesktop?: boolean }>`
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  border: 0.8px solid #71717190;
  border-radius: 16px;
  background-color: #fff;
  height: 130px;
  width: ${({ isDesktop }) => (isDesktop ? 170 : 110)}px;
  border-radius: 16px;
`;

export const TitleCard = styled.h1`
  font-size: 13px;
  font-weight: bold;
  align-self: center;

  color: ${({ theme }) => theme.palette.neutral.main};
`;

export const IconCard = styled.img<{ size: number }>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const TextCard = styled.span`
  margin-top: 4px;
  font-size: 10px;
  font-weight: 12;
  color: ${({ theme }) => theme.palette.neutral.main};
`;
