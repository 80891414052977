import styled from 'styled-components';

export const Collapsible = styled.button`
  margin: 4px 14px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.neutral.main};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 28px);
  :hover {
    transition: opacity 0.3s linear;
    opacity: 0.4;
  }
`;

export const CollapsibleContent = styled.div<{ expand: boolean }>`
  overflow: hidden;
  transition: max-height 0.5s, opacity 0.5s linear;
  max-height: ${({ expand }) => (expand ? '100px' : '0')};
  opacity: ${({ expand }) => (expand ? '1' : '0')};
  flex-direction: column;
  margin: 0 24px;
`;

export const CollapsibleIcon = styled.img<{ expand: boolean }>`
  width: 14px;
  height: 7px;
  transform: ${({ expand }) => (expand ? 'rotate(-180deg)' : 'rotate(0deg)')};
  transition: all 0.5s linear;
`;
