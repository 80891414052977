import React from 'react';

import env from 'react-dotenv';
import InstagramFeed from 'react-ig-feed';
import 'react-ig-feed/dist/index.css';

import Title from 'components/Title';

import * as S from './styles';

interface IProps {
  isDesktop?: boolean;
  section?: string;
}

export default function Instagram({ isDesktop, section }: IProps) {
  return (
    <S.Container id={section}>
      <S.TextContainer isDesktop={isDesktop}>
        <Title title='Acompanhe a nossa rotina completa no' titleColor='Instagram' isDesktop={isDesktop} />
      </S.TextContainer>
      <InstagramFeed token={env.IG_TOKEN} counter={isDesktop ? '12' : '6'} />
    </S.Container>
  );
}
