import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import Home from 'pages/Home';
import About from 'pages/Home/Sections/About';
import Banner from 'pages/Home/Sections/Banner';
import Services from 'pages/Home/Sections/Services';
import Team from 'pages/Home/Sections/Team';
import Instagram from 'pages/Home/Sections/Instagram';

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <AnimatePresence>
        <Routes>
          <Route path='' element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='banner' element={<Banner />} />
          <Route path='services' element={<Services />} />
          <Route path='team' element={<Team />} />
          <Route path='instagram' element={<Instagram />} />
        </Routes>
      </AnimatePresence>
    </BrowserRouter>
  );
}
