import React from 'react';

import * as S from './styles';

interface IProps {
  title: string;
  titleColor?: string;
  titleContinuos?: string;
  subtitle?: string;
  isDesktop?: boolean;
}

export default function Title({ title, titleColor, titleContinuos, subtitle, isDesktop }: IProps) {
  return (
    <S.Container isDesktop={isDesktop}>
      <S.Title>{title}</S.Title>
      {titleColor && <S.TitleColored>{titleColor}</S.TitleColored>}
      {titleContinuos && <S.Title>{titleContinuos}</S.Title>}
      <S.SubTitle>{subtitle}</S.SubTitle>
    </S.Container>
  );
}
