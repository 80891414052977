import { DefaultTheme } from "styled-components";

const defaultTheme: DefaultTheme = {
  borderRadius: "4px",
  palette: {
    primary: {
      main: "#aec5b8",
      background: "#EEEFFC",
    },
    secondary: {
      main: "#083147",
      background: "#F1F1F9",
    },
    neutral: {
      main: "#3E4348",
    },
    contrast: {
      main: "#548498",
      background: "#F8FAF0",
    },
    mono: {
      main: "#FFFFFF",
      background: "#F5F5F5",
    },
  },
  screensSize: {
    smallMobile: 370,
    mobile: 768,
    tablet: 768,
    hd: 1024,
    fullHd: 1200,
    ultraHd: 1600,
  },
};

export default defaultTheme;
