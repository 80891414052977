import styled from 'styled-components';

import { HashLink } from 'react-router-hash-link';

export const Container = styled.header`
  display: flex;
  flex-direction: row;
  height: 80px;
  width: 100%;
  padding: 0 30px 0 30px;
  background: ${({ theme }) => theme.palette.mono.main};
  transition: 0.4s;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 0 14px 0 14px;
  }
`;

export const Row = styled.div`
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 24px;
  margin-bottom: 0.5px solid;
`;


export const Text = styled.h2<{ isDesktop: boolean; isSelected: boolean }>`
  color: ${({ theme, isDesktop, isSelected }) =>
    !isDesktop && isSelected ? `${theme.palette.primary.main};` : `${theme.palette.neutral.main};`};
  font-weight: ${({ isDesktop, isSelected }) => (!isDesktop && isSelected ? `600;` : `400;`)};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1em;
  font-variant: small-caps;
  text-align: start;
  padding: 10px;
  white-space: nowrap;
  cursor: pointer;
  border-bottom: ${({ theme, isDesktop, isSelected }) =>
    !isDesktop && isSelected ? `solid ${theme.palette.primary.main} 2px;` : `solid transparent 2px;`};

  &:hover {
    font-weight: 600;
    transition: border-bottom 0.2s linear;
    border-bottom: ${({ theme, isDesktop, isSelected }) =>
      !isDesktop && !isSelected ? `solid ${theme.palette.primary.main} 2px;` : `solid transparent 2px;`};
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 14px;
  }
`;

export const Logo = styled.img`
  width: 320px;
  height: 70px;
  margin-right: 40px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => `${theme.screensSize.tablet}px`}) {
    width: 70px;
    height: 50px;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.hd}px`}) {
    width: 70px;
    height: 50px;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 70px;
    height: 50px;
  }
`;

export const MobileMenuIcon = styled.img`
  width: 45px;
  height: 45px;
  margin-left: 20px;
  cursor: pointer;
  &:hover {
    transition: opacity 0.3s linear;
    opacity: 0.4;
  }
`;

export const MobileMenuContainer = styled.div`
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.palette.mono.main};
  cursor: pointer;
  position: absolute;
  top: 80px;
  left: 0;
  transition: opacity 0.6s linear;
  z-index: 99;
`;

export const MobileMenuContent = styled.nav`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Link = styled(HashLink)`
  :link {
    text-decoration: none !important;
  }

  :visited {
    text-decoration: none !important;
  }

  :active {
    text-decoration: none !important;
  }
`;

export const Button = styled.button`
  display: flex;
  height: 100%;
  border: none;
  background: transparent;
  align-items: center;

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding-top: 0;
  }
`;

export const ActionButtonContainer = styled.div`
  margin-right: 40px;
  align-items: center;
  gap: 20px;
  @media (max-width: ${({ theme }) => `${theme.screensSize.hd}px`}) {
    margin: 10px;
    margin-bottom: 24px;
  }
`;
