import styled from 'styled-components';

export const Container = styled.div<{isDesktop?: boolean}>`
  justify-content: center;
  flex-direction: column;
  margin-left: ${({ isDesktop }) => isDesktop ? 24 : 18}px
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.secondary.main};
  max-width: 300px;
`;

export const TitleColored = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const SubTitle = styled.h1`
  font-size: 18px;
  margin-top: 8px;
  color: ${({ theme }) => theme.palette.secondary.main};
  max-width: 200px;
`;