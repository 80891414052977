import styled from "styled-components";

export const Container = styled.section`
  flex-direction: column;
  margin: 36px 0 24px 0;
`;

export const TextContainer = styled.div<{ isDesktop?: boolean }>`
  justify-content: center;
  flex-direction: column;
  margin-bottom: 16px;
  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
  }
`;
