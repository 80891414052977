/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from "react";

import useWindowDimensions from "hooks/window-dimensions";

import defaultTheme from "styles/theme";

import DragToScroll from "components/Carousel/DragToScroll";

import Spacer from "components/Spacer";
import { Indicator, IndicatorEntry } from "./Indicator";

import * as S from "./styles";

type IndicatorVariant = "none" | "default" | "overlay";

interface CarouselProps {
  carouselHeight: number;
  itemWidth: number;
  items: any[];
  itemsPerPage?: number;
  showNextButtons?: boolean;
  isCircular?: boolean;
  isInfinite?: boolean;
  indicatorVariant?: IndicatorVariant;
  autoScroll?: boolean;
  autoScrollIntervalSeconds?: number;
  onChangeSlide?: (i: number) => void;
  itemsKeyConstant?: string;
  vet?: boolean;
  isDesktop?: boolean;
}

function Carousel({
  carouselHeight,
  itemWidth,
  items,
  itemsPerPage = 1,
  showNextButtons = false,
  isCircular = true,
  isInfinite = false,
  autoScroll = false,
  autoScrollIntervalSeconds = 4,
  indicatorVariant = "default",
  onChangeSlide,
  itemsKeyConstant = "CAR",
  vet = false,
  isDesktop = false,
}: CarouselProps) {
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState<number>(0);

  const [applyAutoScroll, setApplyAutoScroll] = useState<boolean>(false);
  const [applySmoothBehavior, setApplySmoothBehavior] = useState<boolean>(true);

  const ref: any = useRef();

  const nextSlide = () => {
    if (onChangeSlide) {
      onChangeSlide(
        currentSelectedIndex === items.length - 1 ? 0 : currentSelectedIndex + 1
      );
    }
    setCurrentSelectedIndex(
      currentSelectedIndex === items.length - 1 ? 0 : currentSelectedIndex + 1
    );

    ref?.current.scrollTo({
      left: ref.current.scrollLeft + itemWidth,
      behavior: "smooth",
    });
  };
  const prevSlide = () => {
    if (onChangeSlide) {
      onChangeSlide(
        currentSelectedIndex === 0 ? items.length - 1 : currentSelectedIndex - 1
      );
    }
    setCurrentSelectedIndex(
      currentSelectedIndex === 0 ? items.length - 1 : currentSelectedIndex - 1
    );

    ref?.current.scrollTo({
      left: ref.current.scrollLeft - itemWidth,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (onChangeSlide) {
      onChangeSlide(currentSelectedIndex);
    }
  }, [currentSelectedIndex]);

  useEffect(() => {
    if (autoScroll) {
      setApplySmoothBehavior(false);

      setTimeout(function f() {
        try {
          nextSlide();

          setApplyAutoScroll(!applyAutoScroll);
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }, autoScrollIntervalSeconds * 1000);
    }
  }, [applyAutoScroll]);

  const indicatorData: IndicatorEntry[] = [];

  const { width } = useWindowDimensions();
  const { mobile } = defaultTheme.screensSize;

  return (
    <S.Container>
      <S.Column flexDirection={isDesktop ? "row" : "column"}>
        <S.Content width={itemsPerPage * itemWidth}>
          {showNextButtons && isDesktop && <S.ArrowLeft onClick={prevSlide} />}

          <DragToScroll
            isInfinite={isInfinite}
            itemWidth={itemWidth}
            itemsCountAfterTriplicate={items.length * 3}
            onChangeSlide={setCurrentSelectedIndex}
            setApplySmoothBehavior={setApplySmoothBehavior}
            componentToScrollRef={ref}
          >
            <S.ScrollableContainer
              radius={vet}
              applySmoothBehavior={applySmoothBehavior}
              ref={ref}
              height={carouselHeight}
              width={itemsPerPage * itemWidth}
            >
              {items.map((item: any, index: number) => {
                const entry = {
                  onPress: () => {
                    if (onChangeSlide) {
                      onChangeSlide(index);
                    }

                    ref.current.scrollTo({
                      left: itemWidth * (items.length + index),
                      behavior: "smooth",
                    });
                    setCurrentSelectedIndex(index);
                  },
                  isEnabled: index === currentSelectedIndex,
                };

                indicatorData.push(entry);

                return (
                  <S.CarouselItemWrapper
                    draggable="false"
                    key={`${itemsKeyConstant}-1-${index + 1}`}
                  >
                    {item?.image}
                  </S.CarouselItemWrapper>
                );
              })}

              {isCircular &&
                items.map((item: any, index: number) => {
                  return (
                    <S.CarouselItemWrapper
                      draggable="false"
                      key={`${itemsKeyConstant}-2-${index + 1}`}
                    >
                      {item?.image}
                    </S.CarouselItemWrapper>
                  );
                })}

              {isCircular &&
                items.map((item: any, index: number) => {
                  return (
                    <S.CarouselItemWrapper
                      draggable="false"
                      key={`${itemsKeyConstant}-3-${index + 1}`}
                    >
                      {item?.image}
                    </S.CarouselItemWrapper>
                  );
                })}
            </S.ScrollableContainer>
          </DragToScroll>

          {showNextButtons && isDesktop && <S.ArrowRight onClick={nextSlide} />}
        </S.Content>

        <S.Card carouselHeight={carouselHeight}>
          <S.TitleCard>{items[currentSelectedIndex]?.text?.title}</S.TitleCard>
          <S.DescriptionCard>
            {items[currentSelectedIndex]?.text?.description}
          </S.DescriptionCard>
        </S.Card>
      </S.Column>
      {indicatorVariant === "default" && (
        <>
          <Spacer height={width < mobile ? 20 : 40} />

          <S.IndicatorContainer>
            <Indicator data={indicatorData} />
          </S.IndicatorContainer>
        </>
      )}

      {indicatorVariant === "overlay" && (
        <S.OverlayContainer bottom={50} left={itemWidth / 2}>
          <S.IndicatorContainer>
            <Indicator data={indicatorData} />
          </S.IndicatorContainer>
        </S.OverlayContainer>
      )}
    </S.Container>
  );
}

export { Carousel };
