import React from "react";

import Logo from "assets/images/logo.png";

import Instagram from "assets/images/social-media/logo-instagram.svg";
import Linkedin from "assets/images/social-media/logo-linkedin.svg";

import Collapsible from "./Collapsible";

import * as S from "./styles";

interface IProps {
  isDesktop?: boolean;
}

function Footer({ isDesktop }: IProps) {
  return (
    <S.Container
      isDesktop={isDesktop}
      itemScope
      itemType="http://schema.org/WPFooter"
    >
      <S.LeftContainer>
        <S.Row center={false}>
          <S.Column>
            <S.FaqTitle>Perguntas Frequentes</S.FaqTitle>

            <Collapsible label="O hospital é aberto 24 horas?">
              <S.ButtonText>
                Sim! Nosso hospital dispõe de Médicos Veterinários 24h para
                plantão e emergências. Ao chegar toque o interfone e seja
                prontamente atendido.
              </S.ButtonText>
            </Collapsible>

            <Collapsible label="Vocês possuem laboratório?">
              <S.ButtonText>
                Sim, possuímos laboratório de análises clínicas internamento,
                assim nossos exames tem muito mais agilidade no resultado.
              </S.ButtonText>
            </Collapsible>

            <Collapsible label="Como funciona o internamento?">
              <S.ButtonText>
                Nosso internamento dispõe de no mínimo dois Médicos Veterinários
                24h. Nossos pacientes nunca ficam sozinhos na internação! São
                assistidos 24h, todos os dias da semana!
              </S.ButtonText>
            </Collapsible>
          </S.Column>
        </S.Row>
      </S.LeftContainer>

      <S.RightContainer>
        <S.ColumnAboutCompany>
          <S.Logo
            isMobile={!isDesktop}
            src={Logo}
            alt="Hospital Homeopatas Logo"
          />
          <S.TextAccompany>Hospital Veterinário Homeopatas</S.TextAccompany>
        </S.ColumnAboutCompany>
        <S.RightFooter>
          <S.RowIcons>
            <S.IconButton
              onClick={() =>
                window
                  .open(
                    "https://www.instagram.com/hospitalhomeopatas/",
                    "_blank"
                  )
                  ?.focus()
              }
            >
              <S.Icon src={Instagram} alt="footer.instagramAlt" />
            </S.IconButton>

            {/* <S.IconButton
              onClick={() => window.open("linkedinUrl", "_blank")?.focus()}
            >
              <S.Icon src={Linkedin} alt="footer.linkedinAlt" />
            </S.IconButton> */}
          </S.RowIcons>

          <S.FlagsPlaceholderRight />
        </S.RightFooter>
      </S.RightContainer>
    </S.Container>
  );
}

export default Footer;
