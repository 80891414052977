import styled from 'styled-components';

import UpButton from 'assets/icons/up-button.svg';

export const Container = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  bottom: 40px;
  width: 100%;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1180px;
  justify-content: flex-end;
`;

export const Button = styled.button<{ isVisible: boolean }>`
  overflow: hidden;
  z-index: 1000;
  transition: max-height 0.8s, opacity 0.5s linear;
  max-height: ${({ isVisible }) => (isVisible ? '100px' : '0')};
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  position: fixed;
  right: 40px;
  bottom: 40px;
  :hover {
    opacity: 0.6;
  }
  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    right: 20px;
    bottom: 20px;
  }
`;

export const UpIcon = styled.img.attrs({
  src: UpButton,
})`
  width: 64px;
  height: 64px;
  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 48px;
    height: 48px;
  }
`;
