import React, { useEffect, useState, ReactNode } from 'react';

import { useNavigate } from 'react-router-dom';

import HamburgerIconUri from 'assets/icons/menu-hamburger.svg';
import CloseMenuIconUri from 'assets/icons/close-menu.svg';

import desktopLogoUri from 'assets/images/header-logo.png';
import mobileLogoUri from 'assets/images/logo.png';

import Store from 'contexts/Store';
import defaultTheme from 'styles/theme';

import useWindowDimensions from 'hooks/window-dimensions';

import * as S from './styles';

export interface HeaderItem {
  label: string;
  route?: string;
  scrollTargetId?: string;
}

interface HeaderProps {
  items: HeaderItem[];
  actionButton?: any;
}

function Header({ items, actionButton }: HeaderProps) {
  const { width } = useWindowDimensions();
  const { hd } = defaultTheme.screensSize;

  const { setData, data } = Store.useStore();

  const [localActionButton, setLocalActionButton] = useState<ReactNode>();

  const navigate = useNavigate();

  function isDesktop(): boolean {
    return width > hd;
  }

  const [currentSelectedIndex, setCurrentSelectedIndex] = useState(0);
  const [isMobileMenuExpanded, setIsMobileMenuExpanded] = useState(false);

  useEffect(() => {
    setLocalActionButton(actionButton);
  }, []);

  useEffect(() => {
    const i = data?.index ?? 0;

    setLocalActionButton(<div />);

    setCurrentSelectedIndex(i);

    setIsMobileMenuExpanded(data?.isMobileMenuExpanded);
  }, [data]);

  useEffect(() => {
    if (width > hd) {
      setIsMobileMenuExpanded(false);
    }
  }, [width]);

  function renderButton(i: number, item: HeaderItem) {
    return (
      <div key={i} itemScope itemType='http://schema.org/SiteNavigationElement'>
        <S.Button
          onClick={() => {
            setData({ index: i });

            if (!isDesktop()) {
              setIsMobileMenuExpanded(false);
            }

            if (item.route) {
              navigate(item.route, { replace: true });
            }
          }}
        >
          <S.Text isDesktop={false} isSelected={currentSelectedIndex === i}>
            {item.label}
          </S.Text>
        </S.Button>
      </div>
    );
  }

  function renderButtons() {
    return items.map((item: HeaderItem, i: number) => {
      let button: ReactNode;

      if (item.scrollTargetId) {
        const url = `/#${item.scrollTargetId}`;

        button = (
          <S.Link key={i} to={url} smooth>
            {renderButton(i, item)}
          </S.Link>
        );
      } else {
        button = renderButton(i, item);
      }

      return button;
    });
  }

  function renderMobileSizeMenu(): React.ReactNode {
    return (
      isMobileMenuExpanded && (
        <S.MobileMenuContainer>
          <S.MobileMenuContent>{renderButtons()}</S.MobileMenuContent>
        </S.MobileMenuContainer>
      )
    );
  }

  return (
    <S.Container itemScope itemType='http://schema.org/WPHeader'>
      {renderMobileSizeMenu()}

      <S.Logo
        src={width > hd ? desktopLogoUri : mobileLogoUri}
        alt='header.logoAlt'
        onClick={() => {
          setIsMobileMenuExpanded(false);

          navigate(`${'/'}`, { replace: true });
        }}
      />

      {isDesktop() ? (
        <nav>
          <S.Row>{renderButtons()}</S.Row>
        </nav>
      ) : (
        <>
          {actionButton}

          <S.MobileMenuIcon
            src={!isMobileMenuExpanded ? HamburgerIconUri : CloseMenuIconUri}
            alt={!isMobileMenuExpanded ? 'header.openMenuAlt' : 'header.closeMenuAlt'}
            onClick={() => {
              setIsMobileMenuExpanded(!isMobileMenuExpanded);
            }}
          />
        </>
      )}

      {width > hd && (
        <S.ActionButtonContainer>
          {actionButton}
          {localActionButton}
        </S.ActionButtonContainer>
      )}
    </S.Container>
  );
}

export default Header;
