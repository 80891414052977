import styled from "styled-components";

export const Container = styled.section<{ isDesktop?: boolean }>`
  width: ${({ isDesktop }) => (isDesktop ? 100 : 90)}%;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isDesktop }) => (isDesktop ? "row" : "column")};
`;

export const TextContainer = styled.div<{ isDesktop?: boolean }>`
  justify-content: center;
  flex-direction: column;
  margin-bottom: 16px;
  @media (max-width: ${({ theme }) => `${theme.screensSize.smallMobile}px`}) {
  }
`;

export const BannerImage = styled.img`
  width: 50%;
  height: 400px;

  @media (max-width: ${({ theme }) => `${theme.screensSize.hd}px`}) {
    width: 100%;
    height: 400px;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.tablet}px`}) {
    width: 100%;
    height: 350px;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    width: 100%;
    height: 300px;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.smallMobile}px`}) {
    width: 90%;
    height: 250px;
  }
`;

export const BannerTitle = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.mono.main};
  max-width: 300px;
`;

export const RowContainer = styled.div<{ isDesktop?: boolean }>`
  flex-direction: row;
  margin-right: ${({ isDesktop }) => isDesktop && 64}px;
`;

export const BannerActions = styled.div<{ isDesktop?: boolean }>`
  margin-top: 24px;
  width: 96%;
  height: ${({ isDesktop }) => (isDesktop ? 300 : 140)}px;
  align-self: center;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;

export const CardAction = styled.button<{ isDesktop?: boolean }>`
  margin: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  height: 90px;
  width: ${({ isDesktop }) => (isDesktop ? 140 : 90)}px;
  border-radius: 16px;
`;

export const TitleCard = styled.h1`
  font-size: 24px;
  color: ${({ theme }) => theme.palette.mono.main};
`;

export const IconCard = styled.img`
  height: 25px;
  width: 25px;
  color: ${({ theme }) => theme.palette.neutral.main};
`;

export const TextCard = styled.span`
  margin-top: 6px;
  font-size: 13px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.neutral.main};
`;
