import React from "react";

import HospitalUri from "assets/images/hospital.png";
import PetShopUri from "assets/images/petshop.png";
import ReceptionShopUri from "assets/images/reception.png";
import ClinicRoomUri from "assets/images/clinic-room.png";
import CatRoomUri from "assets/images/cat-clinic-room.png";
import LabUri from "assets/images/lab.png";

import { Carousel } from "components/Carousel";
import Title from "components/Title";

import useWindowDimensions from "hooks/window-dimensions";
import defaultTheme from "styles/theme";

import * as S from "./styles";

interface IProps {
  isDesktop?: boolean;
  section?: string;
}

export default function Hospital({ isDesktop, section }: IProps) {
  const { width } = useWindowDimensions();
  const { mobile } = defaultTheme.screensSize;

  const carouselItemWidth = width < mobile ? 450 : 558;
  const carouselItemHeight = width < mobile ? 250 : 368;

  const hospitalDescription =
    "Fundado em 1996 pelo Médico Veterinário Dr. Samuel Paganelli e sua esposa Ana Pena Paganelli. A família veio morar em Navegantes  para trabalhar no parque Beto Carrero World, onde Dr. Samuel foi o Médico Veterinário e Diretor do Zoológico por 15 anos. Sentindo a necessidade do ramo médico veterinário na cidade que então nasceu a Homeopatas. Foram os pioneiros e única clínica veterinária por 7 anos. Iniciaram com uma pequena sala em sua casa com banho e tosa e consultório. Brevemente a clínica cresceu para uma casa alugada no centro da cidade. Alguns anos depois foi construída a Clínica Veterinária Homeopatas, em novo e amplo local também no centro da cidade. Em julho de 2012 a filha mais velha do casal Dra. Lais Paganelli se forma em medicina veterinária e até hoje é a diretora e cirurgiã da Homeopatas. Em dezembro de 2020 com a necessidade de ampliar ainda mais os serviços de: internação com equipe médica 24h, pronto atendimento 24h, exames de diagnósticos por imagem interno, laboratório de análises interno e especialidades médicas foi então transformada em Hospital Veterinário Homeopatas 24h.";

  const carouselContent: any[] = [
    {
      image: (
        <img
          src={HospitalUri}
          alt="homeopatas-frente"
          width={carouselItemWidth}
          height={carouselItemHeight}
        />
      ),
      text: {
        title: "Hospital Homeopatas",
        description: hospitalDescription,
      },
    },
    {
      image: (
        <img
          src={PetShopUri}
          alt="homeopatas-petshop"
          width={carouselItemWidth}
          height={carouselItemHeight}
        />
      ),
      text: {
        title: "Hospital Homeopatas",
        description: hospitalDescription,
      },
    },
    {
      image: (
        <img
          src={ReceptionShopUri}
          alt="homeopatas-recepcao"
          width={carouselItemWidth}
          height={carouselItemHeight}
        />
      ),
      text: {
        title: "Hospital Homeopatas",
        description: hospitalDescription,
      },
    },
    {
      image: (
        <img
          src={ClinicRoomUri}
          alt="homeopatas-consultorio"
          width={carouselItemWidth}
          height={carouselItemHeight}
        />
      ),
      text: {
        title: "Hospital Homeopatas",
        description: hospitalDescription,
      },
    },
    {
      image: (
        <img
          src={CatRoomUri}
          alt="homeopatas-consultorio"
          width={carouselItemWidth}
          height={carouselItemHeight}
        />
      ),
      text: {
        title: "Hospital Homeopatas",
        description: hospitalDescription,
      },
    },
    {
      image: (
        <img
          src={LabUri}
          alt="homeopatas-consultorio-gatos"
          width={carouselItemWidth}
          height={carouselItemHeight}
        />
      ),
      text: {
        title: "Hospital Homeopatas",
        description: hospitalDescription,
      },
    },
  ];

  return (
    <S.Container id={section}>
      <S.TextContainer isDesktop={isDesktop}>
        <Title
          title="Nossa Estrutura"
          titleColor="Interna"
          subtitle="Confira os detalhes abaixo"
          isDesktop={isDesktop}
        />
      </S.TextContainer>

      <Carousel
        isDesktop={isDesktop}
        autoScroll
        showNextButtons
        items={carouselContent}
        carouselHeight={carouselItemHeight}
        itemWidth={carouselItemWidth}
      />
    </S.Container>
  );
}
