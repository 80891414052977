import React from 'react';

import { Carousel } from 'components/Carousel';

import CarouselImage01 from 'assets/images/carousel.png';

import useWindowDimensions from 'hooks/window-dimensions';
import Title from 'components/Title';

import defaultTheme from 'styles/theme';
import * as S from './styles';

interface IProps {
  isDesktop?: boolean;
  section?: string;
}

export default function About({ isDesktop, section }: IProps) {
  const { width } = useWindowDimensions();
  const { mobile } = defaultTheme.screensSize;

  const carouselItemWidth = width < mobile ? 450 : 558;
  const carouselItemHeight = width < mobile ? 250 : 368;

  const carouselContent: any[] = [
    {
      image: <img src={CarouselImage01} alt='homeopatas-frente' width={carouselItemWidth} height={carouselItemHeight} />,
      text: {
        title: '25 anos de atendimento',
        description:
          'Well-known name in dental and oral care in New York. The journey of this institution started in 1990 under the hands of Dr. Well-known name in dental and oral care in New York. The journey of this institution started in 1990 under the hands of Dr.Well-known name in dental and oral care in New York. The journey of this institution started in 1990 under the hands of Dr.Well-known name in dental and oral care in New York. The journey of this institution started in 1990 under the hands of Dr.Well-known name in dental and oral care in New York. The journey of this institution started in 1990 under the hands of Dr.Well-known name in dental and oral care in New York. The journey of this institution started in 1990 under the hands of Dr.Well-known name in dental and oral care in New York. The journey of this institution started in 1990 under the hands of Dr.Well-known name in dental and oral care in New York. The journey of this institution started in 1990 under the hands of Dr.',
      },
    },
  ];

  return (
    <S.Container id={section}>
      <S.TextContainer isDesktop={isDesktop}>
        <Title title='Nossa História' subtitle='Conheça um pouquinho da nossta tragetória' isDesktop={isDesktop} />
      </S.TextContainer>

      <Carousel
        isDesktop={isDesktop}
        autoScroll
        showNextButtons
        items={carouselContent}
        carouselHeight={carouselItemHeight}
        itemWidth={carouselItemWidth}
      />
    </S.Container>
  );
}
