import React from "react";

import BannerImage from "assets/images/banner.png";
import CallIconUri from "assets/icons/call-icon.svg";
import WhatsIconUri from "assets/icons/whatsapp-icon.svg";
import MapIconUri from "assets/icons/map-icon.svg";

import Title from "components/Title";

import * as S from "./styles";

interface IProps {
  isDesktop?: boolean;
  section?: string;
}

export default function Banner({ isDesktop, section }: IProps) {
  const actionItems: {
    id: number;
    icon: string;
    label: string;
    onPress?: any;
  }[] = [
    {
      id: 0,
      icon: CallIconUri,
      label: "Ligar",
      onPress: () => window.open("tel:4733422245"),
    },
    {
      id: 1,
      icon: WhatsIconUri,
      label: "WhatsApp",
      onPress: () =>
        window.open("https://api.whatsapp.com/send?phone=5547992510624"),
    },
    {
      id: 2,
      icon: MapIconUri,
      label: "Rota",
      onPress: () =>
        window.open(
          "https://www.google.com/maps/dir/?api=1&destination=Hospital Veterinário Homeopatas, R. Ver. Nereu Liberato Nunes, 615 - São Domingos, Navegantes - SC, 88370-232"
        ),
    },
  ];

  return (
    <>
      <S.Container id={section} isDesktop={isDesktop}>
        <S.TextContainer isDesktop={isDesktop}>
          <S.TextContainer isDesktop={isDesktop}>
            <Title
              title="Bem-vindo ao"
              titleColor="Hospital Veterinário"
              titleContinuos="Homeopatas!"
              subtitle="A nossa família cuidando da sua."
              isDesktop={isDesktop}
            />
          </S.TextContainer>
        </S.TextContainer>

        <S.BannerImage src={BannerImage} alt="Banner Homeopatas" />
      </S.Container>
      <S.BannerActions isDesktop={isDesktop}>
        <S.RowContainer isDesktop={isDesktop}>
          {actionItems.map((action) => {
            return (
              <S.CardAction
                key={action.id}
                isDesktop={isDesktop}
                onClick={action.onPress}
              >
                <S.IconCard src={action.icon} />
                <S.TextCard>{action.label}</S.TextCard>
              </S.CardAction>
            );
          })}
        </S.RowContainer>

        {isDesktop && (
          <S.BannerTitle>
            Todos os dias 24 horas cuidando da sua família!
          </S.BannerTitle>
        )}
      </S.BannerActions>
    </>
  );
}
