import styled from 'styled-components';

export const Container = styled.section`
  margin: 28px 0;
  flex-direction: column;
  width: 100%;
`;

export const TextContainer = styled.div<{ isDesktop?: boolean }>`
  justify-content: center;
  flex-direction: column;
  margin: 24px 0;
  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
  }
`;
