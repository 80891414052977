import styled from "styled-components";

import ArrowLeftUri from "assets/icons/carousel-arrow-left.svg";

interface WidthProp {
  width: number;
}

interface OverlayContainerProps {
  bottom: number;
  left: number;
}
export const OverlayContainer = styled.div<OverlayContainerProps>`
  position: absolute;
  bottom: ${({ bottom }) => `${bottom}px;`};
  display: flex;
  width: fit-content;
  height: fit-content;
  align-items: center;
  justify-content: center;
  z-index: 15;
`;

export const Content = styled.div<WidthProp>`
  width: ${({ width }) => `${width}px;`};
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const CarouselItemWrapper = styled.div`
  flex-direction: column;
  scroll-snap-align: center;
  img,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button,
  p {
    user-drag: none;
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;

export const IndicatorContainer = styled.div``;

interface ScrollableContainerProps {
  width: number;
  height: number;
  radius?: boolean;
  applySmoothBehavior: boolean;
}

export const ScrollableContainer = styled.div<ScrollableContainerProps>`
  margin-top: 16px;
  /* width: ${({ width }) => `${width}px;`};
  height: ${({ height }) => `${height}px;`}; */
  border-radius: ${({ radius }) => (radius ? 50 : 3)}%;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-x: scroll;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: ${({ applySmoothBehavior }) =>
    applySmoothBehavior ? `smooth;` : "auto;"};
  scroll-snap-type: ${({ applySmoothBehavior }) =>
    applySmoothBehavior ? `x mandatory;` : "none;"};

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    scroll-snap-type: x mandatory;
    scroll-behavior: auto;
  }
`;

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Column = styled.div<{ flexDirection?: string }>`
  width: 100%;
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: center;
  justify-content: center;
`;

export const ArrowLeft = styled.img.attrs(() => {
  return {
    src: ArrowLeftUri,
    alt: "",
  };
})`
  width: fit-content;
  height: fit-content;
  position: absolute;
  cursor: pointer;
  padding: 20px;
  bottom: calc(50% - 40px);
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
  user-drag: none; /* only works for Safari, do NOT recognized by VS Code lint */
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  :hover {
    transition: opacity 0.3s linear;
    opacity: 0.4;
  }

  @media (max-width: ${({ theme }) => `${theme.screensSize.mobile}px`}) {
    padding: 14px;
    bottom: calc(50% - 28px);
  }
`;

export const ArrowRight = styled(ArrowLeft)`
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);

  left: auto;
  right: 0;
`;

export const TextContainer = styled.div`
  margin-top: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h3`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: 10px;
  font-weight: bold;
`;

export const Description = styled.span`
  text-align: center;
  font-size: 14px;
  color: #71717190;
  /* color: ${({ theme }) => theme.palette.neutral.main}; */
`;

export const Card = styled.div<{ carouselHeight?: number }>`
  align-self: center;
  margin: 24px 12px 12px 12px;
  padding: 16px;
  background-color: #f8fafc90;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
`;

export const TitleCard = styled.h2`
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.neutral.main};
`;

export const DescriptionCard = styled.span`
  color: #71717190;
  font-size: 16px;
`;
